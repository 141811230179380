import React, { useState } from "react";
import { sections } from "../../misc/sections";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import scrollToElement from "scroll-to-element";

const Navigation = ({showNav, setShowNav}) => {
  

  const navScroll = (id) => {
    setShowNav(false);
    const el = document.getElementById(id);
    scrollToElement(el, {
      offset: 0,
      duration: 0,
    });
  };

  return (
    <div>
      <div className="opener">
        <FontAwesomeIcon
          icon={faBars}
          className="closeNav"
          onClick={() => setShowNav(!showNav)}
        />
      </div>
      <div className={`navigation ${showNav ? "active" : ""}`}>
        <FontAwesomeIcon
          icon={faTimes}
          className="closeNav"
          onClick={() => setShowNav(false)}
        />
        {/* <div className="logo">
          <img src="img/logo.png" alt="logo" />
        </div> */}
        <div className="links">
          <ul>
            {sections.map((value, index) => (
              <li key={index}>
                <button onClick={() => navScroll(value.id, index)}>
                  {value.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
