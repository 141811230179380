import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Spinner from '../components/spinner'
import Hero from '../sections/hero'
import About from '../sections/about'
import Portfolio from '../sections/portfolio'
import Services from '../sections/services'
import Testimonials from '../sections/testimonials'
// import Contact from '../sections/contact'

const HomePage = () => {

  return (
    <div>
    <Helmet>
      <title>Jose Benitez - Desarrollador Fullstack</title>
      <meta name="description" content="Jose Benitez - Desarrollador Fullstack" />
    </Helmet>
    <Layout >
        <Hero id="home"  />
        <About id="about" />
        <Services id="services" />
        <Testimonials id="testimonials" />
        <Portfolio id="portfolio" />
        {/* <Contact id="contact" /> */}
    </Layout>
    <Spinner duration={1000} />
  </div>
  )
}

export default HomePage
