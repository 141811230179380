export const sections = [
    {
        name: "Inicio",
        id: "home"
    },
    {
        name: "Sobre mi",
        id: "about"
    },
    {
        name: "Conocimientos",
        id: "services"
    },
    {
        name: "Experiencia",
        id: "testimonials"
    },
    {
        name: "Portafolio",
        id: "portfolio"
    },
    // {
    //     name: "Contacto",
    //     id: "contact"
    // }
]