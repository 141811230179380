import React from "react";
import Particles from "react-particles-js";
import Progress from "../../components/progress";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimationContainer from "../../components/animation-container";
import {
  faGithub,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../context";
import "./styles.scss";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

class Hero extends React.Component {
  static contextType = ThemeContext;

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="about"
        style={{ height: this.context.height }}
      >
        {this.particles()}
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Sobre mi</h4>
              </div>
              <h3>Jose Alberto Benitez Cervantes</h3>
              <div className="separator" />
              <p>
                Desarrollador web y entusiasta de la tecnología, enfocado en la
                innovación y desarrollo de sistemas, soy una persona que
                ​continuamente se encuentra realizando cursos y aprendiendo
                nuevas tecnologías. Tengo experiencia en backend y Frontend.
              </p>
              <div className="social social_icons">
                {/* <FontAwesomeIcon
                  icon={faGithub}
                  className="social_icon"
                  onClick={() => window.open("https://www.github.com")}
                />
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="social_icon"
                  onClick={() => window.open("https://www.twitter.com")}
                />
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="social_icon"
                  onClick={() => window.open("https://www.youtube.com")}
                /> */}
                <FontAwesomeIcon
                  icon={faGithub}
                  className="social_icon"
                  onClick={() => window.open("https://github.com/JoseBenitezCervantes")}
                />
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="social_icon"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/in/jose-benitez46221a171/"
                    )
                  }
                />
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="social_icon"
                  onClick={() => window.open("https://wa.link/frvcbk")}
                />
                
              </div>
            </div>
          </Col>
          <Col md={6} className="skills">
            <div className="line-text">
              <h4>Preparación Profesional</h4>
            </div>

            <div className="skills-container">
              <Col md={10} className="service">
                <AnimationContainer delay={100} animation="fadeInLeft fast">
                  <h3>Universidad Tecnologica Tula-Tepeji</h3>
                  <h6>Ingeniería en Tecnologías de la información</h6>
                  <h6>2014-2019</h6>
                </AnimationContainer>
              </Col>
            </div>
           
          </Col>
        </Row>
      </section>
    );
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
            },
            size: {
              value: 1,
            },
          },
          retina_detect: true,
        }}
      />
    );
  }
}

export default Hero;
