import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BaffleText from "../../components/baffle-text";
import AnimationContainer from "../../components/animation-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faMicrosoft,
  faAws,
  faNodeJs,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPencilRuler,
  faServer,
  faRobot,
  faSmileBeam,
  faPizzaSlice,
  faQuoteRight,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import Counter from "../../components/counter";
import ThemeContext from "../../context";
import "./styles.scss";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.show = this.show.bind(this);
  }

  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              this.context.height !== "auto"
                ? this.context.height * 0.8
                : "inherit",
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>Conocimientos</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="Lo que hago"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    this.context.height !== "auto"
                      ? this.context.height * 0.6
                      : "inherit",
                }}
              >
                <Container>{this.services()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        {/* <Row className="bottom">{this.counters()}</Row> */}
      </section>
    );
  }

  services() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <Row>
          <Col md={4} className="service">
            <AnimationContainer delay={100} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faReact} />
              </div>
              <h3>Front-end React</h3>
              <p>
                Frontend con React <b> Hooks </b> , Uso de Context API, Flux o
                <b> Redux Toolkit</b> Uso de <b> Gatsby </b>
                para paginas estaticas. Y de <b> Next JS </b> para Server Side
                Render. React con Typescript.
              </p>
              <p>
                <b> Apollo Client, GraphQL </b>, Fetch, Axios para comunicación
                con servidores. Estilos con React <b> Material UI </b>,
                <b> SASS </b>, CSS Moderno. Configuración de Service Workers,
                Progressive Web Apps, jwt.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={200} animation="fadeInDown fast">
              <div className="icon">
                <FontAwesomeIcon icon={faMicrosoft} />
              </div>
              <h3>Tecnologías Microsoft</h3>
              <p>
                Creacion de paginas web con <b>ASP.NET</b>, Servicios REST con
                Web API, Test unitarios, lambda expressions Haciendo uso de MVC
                o N Capas.
              </p>
              <p>
                Experiencia con <b>SQL Server</b> creando tablas relacionales
                para millones de registros, Creación de stored procedures,
                MERGE, Common table expression, Indexes. Configuracion de
                usuarios y roles.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={300} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faPencilRuler} className="solid" />
              </div>
              <h3>Diseño Web</h3>
              <p>
                Maqueado de CSS moderno y responsivo con <b>flexbox</b> , css
                grid, child selectors. Experiencia con frameworks de estilos
                como
                <b> bootstrap</b>, Material UI, Prime react.
              </p>
              <p>
                Uso del preprocesador <b>SASS</b> con variables, mixins,
                Nesting, etc.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={400} animation="fadeInUp fast">
              <div className="icon">
                <FontAwesomeIcon icon={faNodeJs} className="solid" />
              </div>
              <h3>Tecnologías con Node JS</h3>
              <p>
                Configuracion de server para APIs con <b>Express</b>, uso de JWT
                para manejo de sesiones, Midelwares, Scrips y paqueterias NPM,
                Conexión con <b> MongoDB </b>
                en <b>Mongo Atlas</b> usando <b>Mongoose</b>.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={500} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faAws} />
              </div>
              <h3>Amazon Web Services</h3>
              <p>
                Integracion y uso de
                <b> CodeBuild</b> con <b>AWS S3</b> y CodeCommit. Configuracion
                de usuarios para <b>CodeCommit</b>,
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={600} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faRobot} className="solid" />
              </div>
              <h3>Otras Hard Skills</h3>
              <p>
                Uso de <b>javascript moderno </b> con Async Await, Arrow
                Functions, scope de variables, "use strict", ternarios,
                closures, spread, promesas, conceptos del ECMAScript actual
              </p>
              <p>Experiencia con entornos de VTEX IO y Vtex Legacy</p>
              <p>
                <b> Doker y Kubernetes </b> para administracion de servidores,
                deploys con <b> netlify </b> conectado a github
              </p>
              <p>
                Conocimientos en Java, Oracle PL/SQL, MySQL, github, team
                foundation server, Bitbucket, Linux, Redes de comunicación.
              </p>
            </AnimationContainer>
          </Col>
        </Row>
      );
    }
  }

  counters() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faSmileBeam}
                value={100}
                text="Happy Clients"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faPizzaSlice}
                value={1000}
                text="Pizzas Ordered"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faQuoteRight}
                value={500}
                text="Reviews"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCode}
                value={50000}
                text="Lines of Code"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
        </Container>
      );
    }
  }
}

export default Services;
