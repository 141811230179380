import React, { useEffect, useState } from "react";
import { ThemeProvider } from "../../context";
import Navigation from "../navigation";
import ScrollLock from "react-scrolllock";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/retro.scss";
import scrollToElement from "scroll-to-element";

const sections = [
  "home",
  "about",
  "services",
  "testimonials",
  "portfolio",
  // "contact",
];
const Layout = ({ children }) => {
  const [stateSizes, setStateSizes] = useState({
    height: 0,
    mobile: false,
    scrolllock: 0,
    width: 0,
  });
  // const [scrolling, setScrolling] = useState(false);
  // const [section_id, setSection_id] = useState(0);
  const [showNav, setShowNav] = useState(false);

  // const updateDimensions = () => {
  //   if (window?.innerWidth && stateSizes.width !== window.innerWidth) {
  //     window.location.reload();
  //   }
    // setStateSizes({
    //   ...stateSizes,
    //   height: window.innerHeight,
    //   width: window.innerWidth,
    // });

    // if (window.innerWidth < 1025) {
    //   setStateSizes({
    //     ...stateSizes,
    //     scrolllock: false,
    //   });
    //   if (window.innerWidth < 992) {
    //     setStateSizes({
    //       ...stateSizes,
    //       mobile: true,
    //     });
    //   }
    // } else {
    //   setStateSizes({
    //     ...stateSizes,
    //     mobile: false,
    //     scrolllock: true,
    //   });
    // }
  // };

  useEffect(() => {
    setStateSizes({
      height: window.innerWidth < 992 ? "auto" : window.innerHeight,
      mobile: window.innerWidth < 992 ? true : false,
      scrolllock: window.innerWidth < 1025 ? false : true,
      width: window.innerWidth,
    });
    // window.addEventListener("resize", updateDimensions);
    return () => {
      // window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  // const wheel = (e) => {
  //   if (!scrolling && !stateSizes.mobile) {
  //     setScrolling(true);
  //     console.log("🚀 ~ file: index.js ~ line 75 ~ wheel ~ e", e)
  //     if (e.deltaY < 0) {
  //       if (
  //         sections[(section_id + sections.length - 1) % sections.length] !==
  //         sections[sections.length - 1]
  //       )
  //         setSection_id((section_id + sections.length - 1) % sections.length);
  //     } else {
  //       if (section_id !== sections.length - 1)
  //         setSection_id((section_id + 1) % sections.length);
  //     }
  //     const el = document.getElementById(sections[section_id]);
  //     scrollToElement(el, {
  //       offset: 0,
  //       ease: "in-out-expo",
  //       duration: 0,
  //     }).on("end", () => {
  //       setScrolling(false);
  //     });
  //   }
  // };

  return (
    <ThemeProvider
      value={{ height: stateSizes.mobile ? "auto" : stateSizes.height }}
    >
      <Navigation {...{ showNav, setShowNav }} />
      <div onClick={() => setShowNav(false)}>{children}</div>
      {/* <ScrollLock isActive={stateSizes.scrolllock} /> */}
    </ThemeProvider>
  );
};

export default Layout;
